import React, { useContext, FormEvent } from 'react';

import { useHistory } from '../hooks/useHistory';
import { useCreateContactContext } from '../context/CreateContact';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Fade from '@mui/material/Fade';

import { useService as useContactsService } from '../services/Contacts';

import { useModal } from '../hooks/useModal';
import { ContactRoutes } from '../routes';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import { Context } from '../context/Mode';
import { useCreateOrderOrigin } from '../context/CreateOrderOrigin';

import Field from '../components/Field';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import CountrySelect from '../components/CountrySelect';
import CreateResourceActions from '../components/CreateResourceActions';
import TestContactDescriptionDialog from '../components/TestContactDescriptionDialog';
import PageHeader from '../components/PageHeader';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        padding: theme.spacing(1),
        fontStyle: 'italic',
        fontSize: 12,
        color: theme.palette.text.disabled,
    },
    corrected: {
        color: theme.palette.warning.main,
    },
    failed: {
        color: theme.palette.error.main,
    },
}));

const VerificationCheckbox = (props: {
    onChange: React.Dispatch<React.SetStateAction<boolean>>;
    checked: boolean;
    title: string;
    description: string;
}) => {
    const handleClick = () => {
        props.onChange((prev) => !prev);
    };

    return (
        <Grid
            container
            spacing={1}
            onClick={handleClick}
            sx={{
                cursor: 'pointer',
                ':hover': {
                    backgroundColor: '#f7f7f7',
                },
            }}
        >
            <Grid item justifyContent="center">
                <Checkbox
                    color="primary"
                    checked={props.checked}
                    onChange={handleClick}
                    onClick={(event) => event.stopPropagation()}
                />
            </Grid>
            <Grid item xs={10}>
                <Typography variant="subtitle2" fontWeight={500}>
                    {props.title}
                </Typography>
                <Typography variant="caption" color="gray">
                    {props.description}
                </Typography>
            </Grid>
        </Grid>
    );
};

const VerificationOptions = (props: {
    forceVerifiedStatus: boolean;
    setForceVerifiedStatus: React.Dispatch<React.SetStateAction<boolean>>;
    skipVerification: boolean;
    setSkipVerification: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    return (
        <>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Verification Options
            </Typography>
            <Grid container direction="column">
                <Grid item xs={12}>
                    <VerificationCheckbox
                        title="Force Verified Status"
                        description="Forces the contact's address status to be 'verified'."
                        checked={props.forceVerifiedStatus}
                        onChange={props.setForceVerifiedStatus}
                    />
                </Grid>
                <Grid item xs={12}>
                    <VerificationCheckbox
                        title="Skip Address Verification"
                        description="Skips address verification for this contact. The contact's verification status will be 'failed' by default and can be overridden by checking 'Force Verified Status'."
                        checked={props.skipVerification}
                        onChange={props.setSkipVerification}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const CreateContact = () => {
    const classes = useStyles();
    const history = useHistory();
    const { live } = useContext(Context);
    const { createOrderOrigin } = useCreateOrderOrigin();

    const service = useContactsService();

    const { dispatch } = useContext(NotificationContext);
    const { isModalOpen, toggleModal } = useModal();

    const {
        description,
        setDescription,
        name,
        setName,
        companyName,
        setCompanyName,
        jobTitle,
        setJobTitle,
        addressLine1,
        setAddressLine1,
        addressLine2,
        setAddressLine2,
        city,
        setCity,
        provinceOrState,
        setProvinceOrState,
        postalOrZip,
        setPostalOrZip,
        countryCode,
        setCountryCode,
        phoneNumber,
        setPhoneNumber,
        email,
        setEmail,
        skipVerification,
        setSkipVerification,
        forceVerifiedStatus,
        setForceVerifiedStatus,
        loading,
        setLoading,
        resetState,
    } = useCreateContactContext();
    console.log({ skipVerification, forceVerifiedStatus });

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!countryCode || countryCode === '') {
            // The HTML5 Input validation will handle this for us
            return;
        }

        try {
            setLoading(true);

            const parts = name.split(' ');

            const firstName =
                parts.length === 1 ? name : parts.slice(0, -1).join(' ');
            const lastName =
                parts.length > 1 ? parts[parts.length - 1] : undefined;

            await service.create({
                addressLine1,
                addressLine2,
                city,
                provinceOrState,
                postalOrZip,
                countryCode,
                skipVerification,
                forceVerifiedStatus,

                firstName,
                lastName,
                companyName,
                jobTitle,
                phoneNumber,
                email,
                description,
            });

            dispatch({
                type: MessageType.SUCCESS,
                message: 'Created contact.',
            });

            if (createOrderOrigin) {
                history.push(createOrderOrigin);
            } else {
                history.push(ContactRoutes.HOME);
            }
            resetState();
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    const TestConfirm = () => {
        if (live) return null;
        switch (description) {
            case 'test failed':
                return (
                    <Fade
                        in={description === 'test failed' && !isModalOpen}
                        unmountOnExit
                    >
                        <Box className={classes.root}>
                            This contact will have an address status of{' '}
                            <span className={classes.failed}>failed.</span>
                        </Box>
                    </Fade>
                );
            case 'test corrected':
                return (
                    <Fade
                        in={description === 'test corrected' && !isModalOpen}
                        unmountOnExit
                    >
                        <Box className={classes.root}>
                            This contact will have an address status of{' '}
                            <span className={classes.corrected}>
                                corrected.
                            </span>
                        </Box>
                    </Fade>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {!live && (
                <TestContactDescriptionDialog
                    open={isModalOpen}
                    onClose={toggleModal}
                />
            )}
            <TopNav />
            <GridPaper direction="column">
                <PageHeader
                    title="Create a Contact"
                    testAlertText="You are in test mode. This contact will only be available in test mode."
                />
                <Grid item>
                    <form onSubmit={onSubmit}>
                        <Box py={4}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Field
                                        value={description}
                                        setValue={setDescription}
                                        label="Description"
                                        id="description"
                                        InputProps={{
                                            endAdornment: !live && (
                                                <IconButton
                                                    size="small"
                                                    onClick={toggleModal}
                                                    data-testid="show-force-status"
                                                >
                                                    <HelpIcon color="disabled" />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                    <TestConfirm />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        value={name}
                                        setValue={setName}
                                        label="Name"
                                        id="name"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        value={companyName}
                                        setValue={setCompanyName}
                                        label="Company"
                                        id="company"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        value={jobTitle}
                                        setValue={setJobTitle}
                                        label="Job Title"
                                        id="job-title"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        value={addressLine1}
                                        setValue={setAddressLine1}
                                        label="Address Line 1"
                                        id="address-line-1"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        value={addressLine2}
                                        setValue={setAddressLine2}
                                        label="Address Line 2"
                                        id="address-line-2"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        value={city}
                                        setValue={setCity}
                                        label="City"
                                        id="city"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        value={provinceOrState}
                                        setValue={setProvinceOrState}
                                        label="Province/State"
                                        id="province-or-state"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        value={postalOrZip}
                                        setValue={setPostalOrZip}
                                        label="Postal/ZIP Code"
                                        id="postal-or-zip"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CountrySelect
                                        size="medium"
                                        field={TextField}
                                        countryCode={countryCode}
                                        setCountryCode={setCountryCode}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        type="tel"
                                        value={phoneNumber}
                                        setValue={setPhoneNumber}
                                        label="Phone Number"
                                        id="phone-number"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        type="email"
                                        value={email}
                                        setValue={setEmail}
                                        label="Email"
                                        id="email"
                                    />
                                </Grid>
                                {live && (
                                    <Grid item xs={6}>
                                        <VerificationOptions
                                            skipVerification={skipVerification}
                                            setSkipVerification={
                                                setSkipVerification
                                            }
                                            forceVerifiedStatus={
                                                forceVerifiedStatus
                                            }
                                            setForceVerifiedStatus={
                                                setForceVerifiedStatus
                                            }
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <CreateResourceActions
                                        disabled={loading}
                                        onCancel={resetState}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreateContact;
