import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

import { useHistory } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import CircularProgress from '@mui/material/CircularProgress';
// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { DropzoneArea } from 'react-mui-dropzone';

import type {
    TextItem,
    TextMarkedContent,
} from 'pdfjs-dist/types/src/display/api';

import { PDFDocument } from 'pdf-lib';

import { LetterRoutes } from '../routes';

import { useNotificationContext } from '../context/Notification';

import { useOrganization } from '../services/Organization';
import { CreateParams as ContactCreateParams } from '../services/Contacts';
import {
    Letter,
    CreateParams,
    useService as useLettersService,
    AddressPlacement,
} from '../services/Letters';
import {
    APIErrorResponse,
    APIRequestError,
    downloadData,
} from '../services/util';
import { ReturnEnvelope } from '../services/ReturnEnvelopes';
import { OrderMailingClass } from '../services/Base';

import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import Button from '../components/Button';
import TableDisplay from '../components/TableDisplay';
import ExpressDeliveryCheckbox from '../components/ExpressDeliveryCheckbox';
import PDFRegionSelect from '../components/PDFRegionSelect';
import ToolTipIconButton from '../components/ToolTipIconButton';
import SelectReturnEnvelope from '../components/SelectReturnEnvelope';
import ConfirmActionDialog from '../components/ConfirmActionDialog';
import CountrySelect from '../components/CountrySelect';
import ExtraServiceSelector from '../components/ExtraServiceSelector';
import MailingClassSelector from '../components/MailingClassSelector';
import SendDate, { minDate } from '../components/SendDate';
import SelectLetterSize from '../components/SelectLetterSize';
import pdfWizardDemo from '../img/pdf-wizard-demo.webm';

type LetterParams = Omit<
    CreateParams,
    'to' | 'from' | 'template' | 'html' | 'returnEnvelope'
> & { returnEnvelope: ReturnEnvelope | null };

enum WizardState {
    REGIONS = 'regions',
    PROCESSING = 'processing',
    CONTACTS = 'contacts',
    PARAMS = 'params',
    CREATING = 'creating',
    CREATED = 'created',
}

enum LetterLengthTypes {
    FIXED = 'fixed',
    VARIABLE = 'variable',
}

export interface Region {
    left: number;
    top: number;
    width: number;
    height: number;
}

interface ExtractContactsParams {
    pdfBuffers: Uint8Array[];

    fromRegion: Region;
    toRegion: Region;

    defaultSenderCountry: string;
    defaultReceiverCountry: string;

    progress: (v: number) => void;
}

interface ContactCreateParamsPair {
    from: ContactCreateParams;
    to: ContactCreateParams;
}

const DEFAULT_REGION: Region = {
    left: 0,
    top: 0,
    width: 2,
    height: 2,
};

const POINTS_PER_INCH = 72;
const DEFAULT_COUNTRY = 'US';

const useDropzoneStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        color: theme.palette.text.secondary,
    },
    icon: {
        color: theme.palette.text.secondary,
    },
}));

const NumberInput = (props: {
    label: string;
    value: number;
    setValue: (v: number) => void;
    min?: number;
    step?: number;
    required?: boolean;
}) => {
    return (
        <TextField
            type="number"
            variant="outlined"
            label={props.label}
            value={props.value}
            inputProps={{
                min: props.min ?? 0,
                step: props.step ?? 1,
            }}
            size="small"
            fullWidth
            onChange={(e) => props.setValue(parseFloat(e.target.value))}
            required={props.required}
        />
    );
};

const loadPDFjs = async () => {
    // Stolen from Calvin's PR
    type PDFjs = typeof import('pdfjs-dist');

    let pdfjs: PDFjs | null = null;

    if (process.env.NODE_ENV !== 'test') {
        pdfjs = await import('pdfjs-dist');
    } else {
        // When testing, we have to use the legacy build of PDFjs:
        // https://github.com/mozilla/pdf.js/issues/14729
        pdfjs = await import('pdfjs-dist/legacy/build/pdf.js');
    }

    // @ts-ignore The worker does not have a decl file
    const workerSrc = await import('pdfjs-dist/build/pdf.worker.entry');
    pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

    return pdfjs;
};

// Group individual text items from PDF into lines by a heuristic:
// If an item is in approximately the same Y position as another item
// as another item, then it is in the same line. If an item a is to the
// left of another item b, a.str should be before b.str in the final address.
const groupIntoLines = (items: TextItem[]): string[] => {
    if (items.length === 0) {
        return [];
    }

    const LINE_Y_THRESHOLD = 0.01;

    // Inner arrays are items on a single line, outer array is lines
    const itemLines: TextItem[][] = [];

    let curY = Number.MIN_VALUE;

    // Sort by reverse Y (since Y increases going upwards)
    items.sort((a, b) => b.transform[5] - a.transform[5]);

    for (const item of items) {
        const itemY = item.transform[5];

        const line = (() => {
            if (Math.abs(curY - itemY) > LINE_Y_THRESHOLD) {
                itemLines.push([]);
                curY = itemY;
            }

            return itemLines[itemLines.length - 1];
        })();

        line.push(item);
    }

    // Sort each line by X
    for (const line of itemLines) {
        line.sort((a, b) => a.transform[4] - b.transform[4]);
    }

    return itemLines.map((line) =>
        line
            .map((item) => item.str.trim())
            .join(' ')
            .trim()
    );
};

const isTextItem = (item: TextItem | TextMarkedContent): item is TextItem =>
    'transform' in item;

const convertRegionToPts = (region: Region) => ({
    left: region.left * POINTS_PER_INCH,
    top: region.top * POINTS_PER_INCH,
    width: region.width * POINTS_PER_INCH,
    height: region.height * POINTS_PER_INCH,
});

// Returns all indices to be included in the specified range (inclusive)
const getPageIndices = (start: number, end: number) => {
    const pageIndices: number[] = [];

    for (let i = start; i <= end; i++) {
        pageIndices.push(i);
    }

    return pageIndices;
};

// Returns each letter page range according to the specified page delimiters
const getPageRanges = (
    delimiterIndices: number[],
    totalPages: number,
    delimiterMarksEOF: boolean
) => {
    const letterRanges: [number, number][] = [];

    if (delimiterMarksEOF) {
        for (let i = 0; i < delimiterIndices.length; i++) {
            // For EOF, start index will be the previous element delimiter index + 1 or 0 (if we're at the first delimiter)
            // End index will always be the current delimiter.
            const startIndex = i === 0 ? 0 : delimiterIndices[i - 1] + 1;
            const endIndex = delimiterIndices[i];

            letterRanges.push([startIndex, endIndex]);
        }

        return letterRanges;
    }

    for (let i = 0; i < delimiterIndices.length; i++) {
        // For SOF, the start index will be the current delimiter
        // End index will be the next element - 1 or the last PDF page (if we are at the last delimiter)
        const startIndex = delimiterIndices[i];
        const endIndex =
            i === delimiterIndices.length - 1
                ? totalPages - 1
                : delimiterIndices[i + 1] - 1;

        letterRanges.push([startIndex, endIndex]);
    }

    return letterRanges;
};

const regionContains = (region: Region, x: number, y: number) =>
    x >= region.left &&
    x <= region.left + region.width &&
    y >= region.top &&
    y <= region.top + region.height;

const splitPDF = async (
    pdfDoc: PDFDocument,
    pagesPerSplit: number,
    progress: (v: number) => void
) => {
    // Progress just for loading the PDF
    const LOAD_PROGRESS = 10;

    const pageCount = pdfDoc.getPageCount();
    const splits: Uint8Array[] = [];

    for (let pageIndex = 0; pageIndex < pageCount; pageIndex += pagesPerSplit) {
        progress(
            Math.ceil(
                LOAD_PROGRESS + (pageIndex / pageCount) * (100 - LOAD_PROGRESS)
            )
        );

        const pageIndices: number[] = [];

        for (let i = 0; i < pagesPerSplit; ++i) {
            if (pageIndex + i >= pageCount) {
                break;
            }

            pageIndices.push(pageIndex + i);
        }

        const splitDoc = await PDFDocument.create();

        const pages = await splitDoc.copyPages(pdfDoc, pageIndices);

        for (const page of pages) {
            splitDoc.addPage(page);
        }

        splits.push(await splitDoc.save());
    }

    progress(100);

    return splits;
};

const splitPDFByDelimiter = async (
    pdfDoc: PDFDocument,
    delimiterText: string,
    delimiterRegion: Region,
    delimiterMarksEOF: boolean,
    progress: (v: number) => void
) => {
    // Progress just for loading the PDF
    const SEARCH_DELIMITER_PROGRESS = 10;
    const delimiterIndices: number[] = [];
    const pdfjs = await loadPDFjs();

    const pageCount = pdfDoc.getPageCount();
    const pdfBuffer = await pdfDoc.save();
    const pdf = await pdfjs.getDocument({ data: pdfBuffer }).promise;

    const delimiterRegionPt = convertRegionToPts(delimiterRegion);

    // Find all pages that contain the delimiter
    for (let i = 0; i < pageCount; ++i) {
        progress(
            Math.ceil(
                (SEARCH_DELIMITER_PROGRESS +
                    (i / pageCount) * (100 - SEARCH_DELIMITER_PROGRESS)) /
                    2
            )
        );

        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });

        const textContent = await page.getTextContent();
        const delimiterItems = [];

        for (const item of textContent.items) {
            if (!isTextItem(item)) {
                continue;
            }

            // We apply the viewport transform in order to get it from the PDFs internal
            // coordinate into browserish coordinates
            const tx = pdfjs.Util.transform(viewport.transform, item.transform);

            const itemXPt = tx[4];
            const itemYPt = tx[5];

            if (regionContains(delimiterRegionPt, itemXPt, itemYPt)) {
                delimiterItems.push(item);
            }
        }

        const delimiterLines = groupIntoLines(delimiterItems);
        if (
            delimiterLines.some((line) =>
                line.toLowerCase().includes(delimiterText.toLowerCase())
            ) ||
            (i === 0 && !delimiterMarksEOF) ||
            (i === pageCount - 1 && delimiterMarksEOF)
        ) {
            delimiterIndices.push(i);
        }
    }

    const splits: Uint8Array[] = [];
    const pageIndices: number[][] = [];

    const letterRanges = getPageRanges(
        delimiterIndices,
        pageCount,
        delimiterMarksEOF
    );

    for (const range of letterRanges) {
        pageIndices.push(getPageIndices(range[0], range[1]));
    }

    // Splitting PDF according to specified indices
    for (const pageIndex of pageIndices) {
        const splitDoc = await PDFDocument.create();

        const pages = await splitDoc.copyPages(pdfDoc, pageIndex);

        for (const page of pages) {
            splitDoc.addPage(page);
        }

        splits.push(await splitDoc.save());
    }

    progress(100);

    return splits;
};

const flattenAndMergePDFs = async (
    files: File[],
    progress: (v: number) => void
) => {
    const mergedPdf = await PDFDocument.create();

    for (let i = 0; i < files.length; i++) {
        const fileArrayBuffer = await files[i].arrayBuffer();
        const fileToMerge = await PDFDocument.load(fileArrayBuffer);
        const form = fileToMerge.getForm();
        form.flatten();

        const copiedPages = await mergedPdf.copyPages(
            fileToMerge,
            fileToMerge.getPageIndices()
        );

        copiedPages.forEach((page) => {
            mergedPdf.addPage(page);
        });

        progress(Math.ceil((100 * i) / files.length));
    }

    return mergedPdf;
};

const extractContacts = async ({
    pdfBuffers,
    fromRegion,
    toRegion,
    progress,
    defaultReceiverCountry,
    defaultSenderCountry,
}: ExtractContactsParams) => {
    const pdfjs = await loadPDFjs();

    // TODO(Apaar): Rather than using separate regions, just use a heuristic
    // of the y position of the text content being the same to group things into
    // the same line. Still need regions but we can probably just have one for the
    // from and one for the to.
    const fromRegionPt = convertRegionToPts(fromRegion);
    const toRegionPt = convertRegionToPts(toRegion);

    const res: ContactCreateParamsPair[] = [];

    for (let i = 0; i < pdfBuffers.length; ++i) {
        progress(Math.ceil((i / pdfBuffers.length) * 100));

        const pdf = await pdfjs.getDocument({
            data: pdfBuffers[i].slice(),
        }).promise;

        // TODO(Apaar): Allow reading from pages other than the first page
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 });

        const textContent = await page.getTextContent();

        // Gather all the items based on region
        const fromItems = [];
        const toItems = [];

        for (const item of textContent.items) {
            if (!isTextItem(item)) {
                continue;
            }

            // We apply the viewport transform in order to get it from the PDFs internal
            // coordinate into browserish coordinates
            const tx = pdfjs.Util.transform(viewport.transform, item.transform);

            const itemXPt = tx[4];
            const itemYPt = tx[5];

            if (regionContains(fromRegionPt, itemXPt, itemYPt)) {
                fromItems.push(item);
            } else if (regionContains(toRegionPt, itemXPt, itemYPt)) {
                toItems.push(item);
            }
        }

        const fromLines = groupIntoLines(fromItems);
        const toLines = groupIntoLines(toItems);

        const { fromName, fromAddress } = (() => {
            // HACK(Apaar): Just for noon health, we make some assumptions
            if (fromLines[0]?.includes('Express Care')) {
                return {
                    fromName: [fromLines[0], fromLines[1]].join(' '),
                    fromAddress: fromLines.slice(2, 4).join(' '),
                };
            }

            return {
                fromName: fromLines[0],
                fromAddress: fromLines.slice(1).join(' '),
            };
        })();

        const toName = toLines[0];
        const toAddress = toLines.slice(1).join(' ');

        res.push({
            from: {
                companyName: fromName,
                addressLine1: fromAddress,
                countryCode: defaultSenderCountry,
            },

            to: {
                companyName: toName,
                addressLine1: toAddress,
                countryCode: defaultReceiverCountry,
            },
        });
    }

    progress(100);

    return res;
};

const ProgressBox = (props: { label: string; progress: number }) => (
    <Paper variant="outlined">
        <Box m={2}>
            <Grid container spacing={2} alignItems="center" direction="column">
                <Grid item>
                    <Typography variant="h6">{props.label}</Typography>
                </Grid>
                <Grid item xs={12} style={{ width: '100%', height: 12 }}>
                    <LinearProgress
                        color="primary"
                        variant="determinate"
                        value={props.progress}
                    />
                </Grid>
            </Grid>
        </Box>
    </Paper>
);

const RegionInput = (props: {
    region: Region;
    setRegion: Dispatch<SetStateAction<Region>>;
}) => {
    const round = (n: number) => Math.round(n * 100) / 100;

    return (
        <FormControl component="fieldset" style={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid container item justifyContent="space-between" spacing={1}>
                    <Grid item xs={3}>
                        <NumberInput
                            label="Left"
                            value={round(props.region.left)}
                            setValue={(v) =>
                                props.setRegion((r) => ({
                                    ...r,
                                    left: v,
                                }))
                            }
                            step={0.01}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberInput
                            label="Top"
                            value={round(props.region.top)}
                            setValue={(v) =>
                                props.setRegion((r) => ({
                                    ...r,
                                    top: v,
                                }))
                            }
                            step={0.01}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberInput
                            label="Width"
                            value={round(props.region.width)}
                            setValue={(v) =>
                                props.setRegion((r) => ({
                                    ...r,
                                    width: v,
                                }))
                            }
                            step={0.01}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberInput
                            label="Height"
                            value={round(props.region.height)}
                            setValue={(v) =>
                                props.setRegion((r) => ({
                                    ...r,
                                    height: v,
                                }))
                            }
                            step={0.01}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </FormControl>
    );
};

const PDFRegionInput = (props: {
    region: Region;
    setRegion: Dispatch<SetStateAction<Region>>;
    files: File[];
    bgColor: string;
    title: string;
}) => {
    const [showRegionPreview, setShowRegionPreview] = useState(true);

    const togglePreview = () => {
        setShowRegionPreview((prev) => !prev);
    };

    return (
        <Grid item container spacing={3}>
            <Grid item>
                <Typography variant="h6">{props.title}</Typography>
                <Typography>
                    Position the rectangle over the relevant address in order to
                    extract it.
                </Typography>
            </Grid>
            <Grid item container xs={12} spacing={1}>
                <Grid item xs={11}>
                    <RegionInput
                        region={props.region}
                        setRegion={props.setRegion}
                    />
                </Grid>
                <Grid item container justifyContent="center" xs={1}>
                    <Grid item>
                        <ToolTipIconButton
                            icon={
                                showRegionPreview
                                    ? VisibilityIcon
                                    : VisibilityOffIcon
                            }
                            title="Toggle PDF preview"
                            onClick={togglePreview}
                            size="small"
                        />
                    </Grid>
                </Grid>
            </Grid>
            {showRegionPreview && (
                <Grid item xs={12}>
                    <PDFRegionSelect
                        bgColor={props.bgColor}
                        PDF={props.files}
                        region={props.region}
                        setRegion={props.setRegion}
                    />
                </Grid>
            )}
        </Grid>
    );
};

const ContactCountriesSelect = (props: {
    senderCountry: string;
    setSenderCountry: Dispatch<SetStateAction<string>>;
    receiverCountry: string;
    setReceiverCountry: Dispatch<SetStateAction<string>>;
}) => {
    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant="h6">Contact Countries</Typography>
                <Typography>
                    Specify the countries to be assigned to the different
                    contacts.
                </Typography>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={6}>
                    <CountrySelect
                        size="small"
                        field={TextField}
                        countryCode={props.senderCountry}
                        setCountryCode={props.setSenderCountry}
                        label="Sender Country"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CountrySelect
                        size="small"
                        field={TextField}
                        countryCode={props.receiverCountry}
                        setCountryCode={props.setReceiverCountry}
                        label="Recipient Country"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const SelectablePageCount = (props: {
    selected: boolean;
    title: string;
    description: string;
    children: React.ReactNode;
    onClick: () => void;
}) => {
    return (
        <Grid
            item
            xs={12}
            md={6}
            onClick={props.onClick}
            sx={(theme) => ({
                ':hover': !props.selected
                    ? // Only show hover style changes if we are not currently
                      // selected
                      {
                          backgroundColor: '#e0ebff',
                          color: '#3279fa',
                          h6: {
                              color: '#1162f5',
                          },
                      }
                    : undefined,
                // TODO: Input styles
                cursor: !props.selected ? 'pointer' : undefined,
                color: !props.selected
                    ? theme.palette.text.disabled
                    : '#668fd9',
                backgroundColor: props.selected ? '#f2f6fc' : undefined,
                borderRadius: '2px',
                h6: {
                    color: props.selected ? '#497ddb' : undefined,
                },
            })}
        >
            <Grid
                container
                padding={2}
                height="100%"
                direction="column"
                justifyContent="space-between"
            >
                <Grid item>
                    <Typography variant="h6">{props.title}</Typography>
                    <Typography sx={{ color: 'inherit' }}>
                        {props.description}
                    </Typography>
                </Grid>
                <Grid item mt={4}>
                    {props.children}
                </Grid>
            </Grid>
        </Grid>
    );
};

const DelimiterEOFCheckbox = (props: {
    delimiterMarksEOF: boolean;
    setDelimiterMarksEOF: Dispatch<SetStateAction<boolean>>;
}) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={<Checkbox sx={{ color: 'inherit' }} />}
                label={
                    <Typography sx={{ color: 'inherit' }}>
                        Marker indicates the last page of a letter.
                    </Typography>
                }
                onChange={() =>
                    props.setDelimiterMarksEOF((prev: boolean) => !prev)
                }
                sx={{ 'margin-bottom': '5px' }}
            />
        </FormGroup>
    );
};

const PageCountSelector = (props: {
    letterLengthType: LetterLengthTypes;
    setLetterLengthType: Dispatch<SetStateAction<LetterLengthTypes>>;
    pagesPerLetter: number;
    setPagesPerLetter: Dispatch<SetStateAction<number>>;
    letterDelimiterText: string;
    setLetterDelimiterText: Dispatch<SetStateAction<string>>;
    delimiterMarksEOF: boolean;
    setDelimiterMarksEOF: Dispatch<SetStateAction<boolean>>;
}) => {
    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant="h6">Letter Page Counts</Typography>
                <Typography>
                    We will automatically split up your PDF file into individual
                    letters. We need to know how many pages each letter has.
                    Please select one of the options below.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper variant="outlined">
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="stretch"
                    >
                        <SelectablePageCount
                            selected={
                                props.letterLengthType ===
                                LetterLengthTypes.FIXED
                            }
                            onClick={() =>
                                props.setLetterLengthType(
                                    LetterLengthTypes.FIXED
                                )
                            }
                            title="Fixed Page Count"
                            description="The number of pages per letter if every letter has the same number of pages."
                        >
                            <TextField
                                type="number"
                                variant="outlined"
                                label="Pages Per Letter"
                                value={props.pagesPerLetter}
                                inputProps={{
                                    min: 1,
                                    step: 1,
                                }}
                                size="small"
                                fullWidth
                                onChange={(e) =>
                                    props.setPagesPerLetter(
                                        parseInt(e.target.value)
                                    )
                                }
                                required={
                                    props.letterLengthType ===
                                    LetterLengthTypes.FIXED
                                }
                            />
                        </SelectablePageCount>
                        <SelectablePageCount
                            selected={
                                props.letterLengthType ===
                                LetterLengthTypes.VARIABLE
                            }
                            onClick={() =>
                                props.setLetterLengthType(
                                    LetterLengthTypes.VARIABLE
                                )
                            }
                            title="Variable Page Count"
                            description="This text helps us identify a new letter when splitting your PDF."
                        >
                            <DelimiterEOFCheckbox
                                delimiterMarksEOF={props.delimiterMarksEOF}
                                setDelimiterMarksEOF={
                                    props.setDelimiterMarksEOF
                                }
                            />
                            <TextField
                                type="text"
                                variant="outlined"
                                value={props.letterDelimiterText}
                                size="small"
                                placeholder="New Letter Marker"
                                fullWidth
                                label="New Letter Marker"
                                onChange={(e) =>
                                    props.setLetterDelimiterText(e.target.value)
                                }
                                required={
                                    props.letterLengthType ===
                                    LetterLengthTypes.VARIABLE
                                }
                            />
                        </SelectablePageCount>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

const PDFWizard = () => {
    const history = useHistory();
    const org = useOrganization([history.location]);

    const lettersService = useLettersService();
    const dropzoneClasses = useDropzoneStyles();
    const { dispatchError } = useNotificationContext();

    const localLoader = (name: string, defaultValue: any) => () => {
        const region = localStorage.getItem(name);

        if (region) {
            return JSON.parse(region);
        }

        return defaultValue;
    };

    const [state, setState] = useState<WizardState>(WizardState.REGIONS);

    const [files, setFiles] = useState<File[]>([]);

    const [progressLabel, setProgressLabel] = useState<string>();
    const [progress, setProgress] = useState<number | null>(null);
    const [defaultSenderCountry, setDefaultSenderCountry] =
        useState(DEFAULT_COUNTRY);
    const [defaultReceiverCountry, setDefaultReceiverCountry] =
        useState(DEFAULT_COUNTRY);

    const [letterLengthType, setLetterLengthType] = useState<LetterLengthTypes>(
        LetterLengthTypes.FIXED
    );

    // Text that marks a new letter
    // Lets us know how to split the PDF when letters aren't the same length
    const [letterDelimiterText, setLetterDelimiterText] = useState(
        localLoader('letterDelimiterText', '')
    );
    // Indicates if the letterDelimiterText should indicate the start or end of a new letter
    const [delimiterMarksEOF, setDelimiterMarksEOF] = useState(false);
    const [pagesPerLetter, setPagesPerLetter] = useState(
        localLoader('pagesPerLetter', 1)
    );

    const [delimiterRegion, setDelimiterRegion] = useState<Region>(
        localLoader('delimiterRegion', DEFAULT_REGION)
    );
    const [fromRegion, setFromRegion] = useState<Region>(
        localLoader('fromRegion', DEFAULT_REGION)
    );
    const [toRegion, setToRegion] = useState<Region>(
        localLoader('toRegion', DEFAULT_REGION)
    );

    // When these are no longer undefined, we can show the user a preview of the contacts

    // @eslint-ignore-next-line
    const [pdfBuffers, setPDFBuffers] = useState<Uint8Array[]>();
    const [contacts, setContacts] = useState<ContactCreateParamsPair[]>();

    const [letterParams, setLetterParams] = useState<LetterParams>(
        localLoader('letterParams', undefined)
    );

    // Once this is non-null and progress >= 100, we are done creating letters
    const [letters, setLetters] = useState<(Letter | APIErrorResponse)[]>();

    const [showDialog, setShowDialog] = useState(true);

    // Store the regions to localStorage as they are updated
    useEffect(() => {
        localStorage.setItem('fromRegion', JSON.stringify(fromRegion));
        localStorage.setItem('toRegion', JSON.stringify(toRegion));
        localStorage.setItem(
            'delimiterRegion',
            JSON.stringify(delimiterRegion)
        );
        localStorage.setItem('pagesPerLetter', JSON.stringify(pagesPerLetter));
        localStorage.setItem(
            'letterDelimiterText',
            JSON.stringify(letterDelimiterText)
        );
        localStorage.setItem(
            'letterParams',
            JSON.stringify(letterParams ?? null)
        );
    }, [
        fromRegion,
        toRegion,
        delimiterRegion,
        pagesPerLetter,
        letterParams,
        letterDelimiterText,
    ]);

    const onRegionsNext = async () => {
        if (!files?.length) {
            return dispatchError('You must upload at least one file');
        }

        if (letterLengthType === LetterLengthTypes.FIXED && !pagesPerLetter) {
            return dispatchError(
                'You must specify the number of pages per letter to continue.'
            );
        }

        if (
            letterLengthType === LetterLengthTypes.VARIABLE &&
            !letterDelimiterText
        ) {
            return dispatchError(
                'You must specify the letter delimiter text to continue.'
            );
        }

        setState(WizardState.PROCESSING);

        files.length > 1
            ? setProgressLabel('Loading & Merging PDFs...')
            : setProgressLabel('Loading PDF...');
        setProgress(0);

        try {
            const mergedFile = await flattenAndMergePDFs(files, (v) => {
                setProgress(v);
            });
            setProgress(0);

            const pdfBuffers =
                letterLengthType === LetterLengthTypes.FIXED
                    ? await splitPDF(mergedFile, pagesPerLetter, (v) => {
                          setProgressLabel('Splitting PDF...');
                          setProgress(v);
                      })
                    : await splitPDFByDelimiter(
                          mergedFile,
                          letterDelimiterText,
                          delimiterRegion,
                          delimiterMarksEOF,
                          (v) => {
                              setProgressLabel('Splitting PDF...');
                              setProgress(v);
                          }
                      );

            setPDFBuffers(pdfBuffers);

            setProgressLabel('Extracting Contacts...');
            setProgress(0);

            const contacts = await extractContacts({
                pdfBuffers,
                fromRegion,
                toRegion,
                defaultReceiverCountry,
                defaultSenderCountry,
                progress: setProgress,
            });

            // Hide the progress box
            setProgressLabel(undefined);
            setContacts(contacts);

            setState(WizardState.CONTACTS);
        } catch (err: any) {
            setState(WizardState.REGIONS);
            setProgressLabel(undefined);
            console.error(err);

            if (err.message.includes('is encrypted')) {
                dispatchError(
                    'One or more of the provided files may be encrypted.'
                );
            } else {
                dispatchError(err.message);
            }
        }
    };

    const onContactsNext = async () => {
        setState(WizardState.PARAMS);
        setLetterParams({
            color: false,
            doubleSided: false,
            addressPlacement: AddressPlacement.TOP_FIRST_PAGE,
            express: false,
            returnEnvelope: null,
        });
    };

    const onCreate = async () => {
        if (!contacts || !pdfBuffers || !letterParams) {
            return;
        }

        setState(WizardState.CREATING);

        setProgress(0);
        setProgressLabel('Creating Letters...');

        setLetters(
            contacts.map(() => ({
                object: 'error',
                error: {
                    message: 'No letter was created.',
                    type: 'unknown',
                },
            }))
        );

        // Do 5 letters at a time
        const BATCH_SIZE = 5;

        const promises = [];

        for (let i = 0; i < contacts.length; ++i) {
            const contactPair = contacts[i];

            promises.push(
                (async () => {
                    try {
                        const letter = await lettersService.createUploadPDF({
                            'from[companyName]':
                                contactPair.from.companyName ?? '',
                            'from[addressLine1]': contactPair.from.addressLine1,
                            'from[countryCode]':
                                contactPair.from.countryCode ?? 'US',
                            'to[companyName]': contactPair.to.companyName ?? '',
                            'to[addressLine1]':
                                contactPair.to.addressLine1 ?? '',
                            'to[countryCode]':
                                contactPair.to.countryCode ?? 'US',
                            ...letterParams,
                            returnEnvelope: letterParams.returnEnvelope?.id,
                            pdf: new Blob([pdfBuffers[i]], {
                                type: 'application/pdf',
                            }),
                        });

                        // Append to letters
                        setLetters((prev) => {
                            const newLetters = [...prev!];
                            newLetters[i] = letter;
                            return newLetters;
                        });
                    } catch (err: any) {
                        const requestErr =
                            err instanceof APIRequestError ? err : null;

                        const errResponse: APIErrorResponse = {
                            object: 'error',
                            error: {
                                type: requestErr?.type ?? 'unknown',
                                message: requestErr?.message ?? err.toString(),
                            },
                        };

                        setLetters((prev) => {
                            const newLetters = [...prev!];
                            newLetters[i] = errResponse;
                            return newLetters;
                        });
                    }

                    setProgress((prev) =>
                        Math.max(
                            prev ?? 0,
                            Math.ceil((i / contacts.length) * 100)
                        )
                    );
                })()
            );

            if (promises.length >= BATCH_SIZE) {
                await Promise.all(promises);
                promises.length = 0;
            }
        }

        setProgress(100);
        setProgressLabel(undefined);
        setState(WizardState.CREATED);
    };

    const onDownloadReport = async () => {
        if (!contacts || !letters) {
            return;
        }

        // HACK(Apaar): Copied from BatchSend component
        const headers = `ID,To,Status`;
        const rows = letters.map((o, i) => {
            const name = contacts[i].to.companyName;
            const csvSafeName = `"${(
                name ?? 'Contact at Row: ' + (i + 1).toString()
            ).replace(/"/g, "'")}"`;

            if (o.object === 'error') {
                return `,${csvSafeName},Error: ${o.error.message}`;
            }

            return `${o.id},${csvSafeName},Success`;
        });

        const payload = headers + '\n' + rows.join('\n');

        downloadData(payload, 'batch_send.csv', 'text/csv');
    };

    // If the string is null, then the button is not displayed
    const stateToButtonLabel: Record<WizardState, string | null> = {
        [WizardState.REGIONS]: 'Next',
        [WizardState.PROCESSING]: null,
        [WizardState.CONTACTS]: 'Next',
        [WizardState.PARAMS]: 'Create',
        [WizardState.CREATING]: null,
        [WizardState.CREATED]: 'Download Report',
    };

    const stateToButtonHandler: Record<WizardState, (() => void) | null> = {
        [WizardState.REGIONS]: onRegionsNext,
        [WizardState.PROCESSING]: null,
        [WizardState.CONTACTS]: onContactsNext,
        [WizardState.PARAMS]: onCreate,
        [WizardState.CREATING]: null,
        [WizardState.CREATED]: onDownloadReport,
    };

    // If the handler is null, then the back button is not displayed
    const stateToBackHandler: Record<WizardState, (() => void) | null> = {
        [WizardState.REGIONS]: null,
        [WizardState.PROCESSING]: null,
        [WizardState.CONTACTS]: () => {
            setPDFBuffers(undefined);
            setContacts(undefined);
            setState(WizardState.REGIONS);
        },
        [WizardState.PARAMS]: () => {
            setState(WizardState.CONTACTS);
        },
        [WizardState.CREATING]: null,
        [WizardState.CREATED]: null,
    };

    const buttonLabel = stateToButtonLabel[state];
    const buttonHandler =
        stateToButtonHandler[state] &&
        (() => {
            // HACK(Apaar): Check validity of all active inputs imperatively
            document
                .querySelectorAll('input')
                .forEach((e) => e.reportValidity());

            return stateToButtonHandler[state]!();
        });
    const backHandler = stateToBackHandler[state];

    if (org === undefined) {
        return (
            <Grid
                container
                item
                style={{ minHeight: '20vh' }}
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress />
            </Grid>
        );
    }

    if (!org.enablePDFWizard) {
        return (
            <ConfirmActionDialog
                open={showDialog}
                onClose={() => {
                    setShowDialog(false);
                    history.goBack();
                }}
                confirm={() =>
                    window.open(
                        'https://postgrid.com/contact-us',
                        '_blank',
                        'noopener'
                    )
                }
                actionLabel="Contact Sales"
                title="The PDF Wizard is not enabled for your organization"
                text="This tool automatically picks up letters and their recipients from 
                    pre-generated PDF files and sends them out using PostGrid,\nno code or CSV 
                    files required. Please contact sales to enable the PDF wizard for your organization."
                demoVideo={pdfWizardDemo}
                demoVideoWidth="800px"
                maxWidth="988px"
            />
        );
    }

    return (
        <>
            <TopNav />
            <GridPaper spacing={4} direction="column">
                <Grid item>
                    <Typography variant="h5">PDF Wizard</Typography>
                </Grid>
                {state === WizardState.REGIONS && (
                    <>
                        <Grid item>
                            <Alert color="info" variant="outlined">
                                This tool will take your PDF file, split it up
                                based on the pages per letter you specify,
                                extract the relevant contact information, and
                                create letters automatically.
                            </Alert>
                        </Grid>
                        <Grid item>
                            <DropzoneArea
                                inputProps={
                                    {
                                        required: true,
                                        'data-testid': 'dropzone-area-div',
                                    } as React.InputHTMLAttributes<HTMLInputElement>
                                }
                                acceptedFiles={['.pdf']}
                                dropzoneText={
                                    files.length > 0
                                        ? files
                                              .map((file) => file.name)
                                              .join(', ')
                                        : 'Drag and drop files here or click.'
                                }
                                showPreviewsInDropzone={false}
                                filesLimit={10}
                                // Up to 100mb
                                maxFileSize={1024 * 1024 * 1024 * 100}
                                alertSnackbarProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    },
                                }}
                                onChange={(files) => setFiles(files)}
                                classes={dropzoneClasses}
                            />
                        </Grid>
                        <Grid item>
                            <Alert color="info" variant="outlined">
                                We will use the region coordinates (specified in
                                inches) to pick up information from your PDF
                                file. Click "Show PDF Preview" underneath the
                                inputs below to use our measuring tool and
                                adjust your regions.
                            </Alert>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={8} direction="column">
                                <Grid item xs={12}>
                                    <ContactCountriesSelect
                                        senderCountry={defaultSenderCountry}
                                        setSenderCountry={
                                            setDefaultSenderCountry
                                        }
                                        receiverCountry={defaultReceiverCountry}
                                        setReceiverCountry={
                                            setDefaultReceiverCountry
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <PageCountSelector
                                        letterLengthType={letterLengthType}
                                        setLetterLengthType={
                                            setLetterLengthType
                                        }
                                        pagesPerLetter={pagesPerLetter}
                                        setPagesPerLetter={setPagesPerLetter}
                                        letterDelimiterText={
                                            letterDelimiterText
                                        }
                                        setLetterDelimiterText={
                                            setLetterDelimiterText
                                        }
                                        delimiterMarksEOF={delimiterMarksEOF}
                                        setDelimiterMarksEOF={
                                            setDelimiterMarksEOF
                                        }
                                    />
                                </Grid>
                                {letterLengthType ===
                                    LetterLengthTypes.VARIABLE && (
                                    <PDFRegionInput
                                        title="New Letter Marker Region"
                                        bgColor="#21D7F31A"
                                        region={delimiterRegion}
                                        setRegion={setDelimiterRegion}
                                        files={files}
                                    />
                                )}
                                <PDFRegionInput
                                    bgColor="#F321961A"
                                    region={fromRegion}
                                    setRegion={setFromRegion}
                                    files={files}
                                    title="Sender Region"
                                />
                                <PDFRegionInput
                                    bgColor="#96F3211A"
                                    region={toRegion}
                                    setRegion={setToRegion}
                                    files={files}
                                    title="Recipient Region"
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                {state === WizardState.CONTACTS && contacts && (
                    <>
                        <Grid container item xs={12} justifyContent="center">
                            <Grid item xs={12}>
                                <Alert
                                    color="info"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                >
                                    These are the contacts we detected from your
                                    PDF file. Please confirm their information.
                                    Each row corresponds to a single letter.
                                </Alert>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TableDisplay
                                columns={[
                                    'Row Number',
                                    'Recipient Name',
                                    'Recipient Address',
                                    'Sender Name',
                                    'Sender Address',
                                ]}
                                // FIXME(Apaar): This property is poorly named; it refers to
                                // whether we should show a skeleton while the table is loading
                                show={false}
                                showEmptyTable
                                stickyHeader
                                containerStyle={{
                                    height: 400,
                                }}
                            >
                                {contacts.map((c, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>
                                            {c.to.companyName}
                                        </TableCell>
                                        <TableCell>
                                            {c.to.addressLine1}
                                        </TableCell>
                                        <TableCell>
                                            {c.from.companyName}
                                        </TableCell>
                                        <TableCell>
                                            {c.from.addressLine1}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableDisplay>
                        </Grid>
                    </>
                )}
                {state === WizardState.PARAMS && letterParams && (
                    <Grid container item spacing={2}>
                        <Grid item xs={12}>
                            <Alert color="info" variant="outlined">
                                Please select the parameters for your letters.
                                These will apply to all of the letters in this
                                batch.
                            </Alert>
                        </Grid>
                        <SelectLetterSize
                            xs={3}
                            size={letterParams.size ?? ''}
                            onChange={(newSize) => {
                                if (newSize === letterParams.size) {
                                    return;
                                }
                                return setLetterParams((prev) => ({
                                    ...prev!,
                                    size: newSize,
                                }));
                            }}
                            destinationCountryCode={defaultSenderCountry}
                            selectTestID="letter-size"
                        />
                        <ExtraServiceSelector
                            xs={3}
                            extraService={letterParams.extraService ?? ''}
                            onChange={(newExtraService) => {
                                setLetterParams((prev) => ({
                                    ...prev!,
                                    extraService: newExtraService || undefined,
                                }));
                            }}
                            disabled={!!letterParams.express}
                            selectTestID="letter-extra-service"
                        />
                        <MailingClassSelector
                            xs={3}
                            mailingClass={
                                letterParams.mailingClass ??
                                OrderMailingClass.FIRST_CLASS
                            }
                            onChange={(newMailingClass) => {
                                setLetterParams((prev) => ({
                                    ...prev!,
                                    mailingClass: newMailingClass,
                                }));
                            }}
                            disabled={!!letterParams.express}
                            selectTestID="letter-mailing-class"
                        />
                        <SendDate
                            xs={3}
                            sendDate={letterParams.sendDate ?? minDate()}
                            setSendDate={(e) =>
                                setLetterParams((prev) => ({
                                    ...prev!,
                                    sendDate: e,
                                }))
                            }
                            showSubscriptionPopup={!org?.stripeSubscription}
                        />
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={letterParams.color}
                                        onChange={(e) =>
                                            setLetterParams((prev) => ({
                                                ...prev!,
                                                color: e.target.checked,
                                            }))
                                        }
                                        inputProps={
                                            {
                                                'data-testid': 'letter-color',
                                            } as React.InputHTMLAttributes<HTMLInputElement>
                                        }
                                    />
                                }
                                label="Color"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={letterParams.doubleSided}
                                        onChange={(e) => {
                                            setLetterParams((prev) => ({
                                                ...prev!,
                                                doubleSided: e.target.checked,
                                            }));
                                        }}
                                        inputProps={
                                            {
                                                'data-testid':
                                                    'letter-double-sided',
                                            } as React.InputHTMLAttributes<HTMLInputElement>
                                        }
                                    />
                                }
                                label="Double Sided"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={
                                            letterParams.addressPlacement ===
                                            AddressPlacement.INSERT_BLANK_PAGE
                                        }
                                        onChange={(e) => {
                                            const addressPlacement = e.target
                                                .checked
                                                ? AddressPlacement.INSERT_BLANK_PAGE
                                                : AddressPlacement.TOP_FIRST_PAGE;

                                            setLetterParams((prev) => ({
                                                ...prev!,
                                                addressPlacement,
                                            }));
                                        }}
                                        inputProps={
                                            {
                                                'data-testid':
                                                    'letter-blank-page',
                                            } as React.InputHTMLAttributes<HTMLInputElement>
                                        }
                                    />
                                }
                                label="Insert Blank Page for Address"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={
                                            letterParams.perforatedPage === 1
                                        }
                                        onChange={(e) => {
                                            setLetterParams((prev) => ({
                                                ...prev!,
                                                perforatedPage: e.target.checked
                                                    ? 1
                                                    : undefined,
                                            }));
                                        }}
                                        inputProps={
                                            {
                                                'data-testid':
                                                    'letter-perforate',
                                            } as React.InputHTMLAttributes<HTMLInputElement>
                                        }
                                    />
                                }
                                label="Perforate First Page"
                            />
                        </Grid>
                        <ExpressDeliveryCheckbox
                            checked={letterParams.express}
                            setChecked={(v) =>
                                setLetterParams((prev) => ({
                                    ...prev!,
                                    express: v,
                                }))
                            }
                            checkboxTestID="letter-express"
                        />
                        <Grid item xs={12}>
                            <SelectReturnEnvelope
                                label="Select a Return Envelope"
                                returnEnvelope={letterParams.returnEnvelope}
                                setReturnEnvelope={(returnEnvelope) => {
                                    setLetterParams((prev) => ({
                                        ...prev,
                                        returnEnvelope,
                                    }));
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                {progressLabel && (
                    <Grid item>
                        <ProgressBox
                            label={progressLabel}
                            progress={progress ?? 0}
                        />
                    </Grid>
                )}
                <Grid container item spacing={2} justifyContent="flex-start">
                    {buttonLabel && buttonHandler && (
                        <>
                            <Grid item>
                                <Button
                                    size="large"
                                    onClick={buttonHandler}
                                    id="button-for-next"
                                    data-testid="button-for-next"
                                >
                                    {buttonLabel}
                                </Button>
                            </Grid>
                            {state === WizardState.CREATED && (
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        onClick={() =>
                                            history.push(LetterRoutes.HOME)
                                        }
                                    >
                                        View Letters
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}
                    {backHandler && (
                        <Grid item>
                            <Button
                                size="large"
                                variant="outlined"
                                onClick={backHandler}
                            >
                                Back
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </GridPaper>
        </>
    );
};

export default PDFWizard;
