import React, { useCallback, useState } from 'react';

import { Switch, Route, useHistory } from 'react-router-dom';

import { usePagination } from '../hooks/usePagination';
import { useModal } from '../hooks/useModal';

import { CreateOrderOriginProvider } from '../context/CreateOrderOrigin';
import { ContactRoutes } from '../routes';

import InfoIcon from '@mui/icons-material/Info';

import {
    Contact,
    fullName,
    useService as useContactsService,
} from '../services/Contacts';

import {
    formatTableDate,
    ListParams,
    APIErrorResponse,
    downloadData,
} from '../services/util';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import CreateContact from './CreateContact';
import ViewContact from './ViewContact';
import Button from '../components/Button';
import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import UploadContactsDialog from '../components/UploadContactsDialog';
import PageHeader from '../components/PageHeader';
import TableDisplay from '../components/TableDisplay';
import { CreateContactProvider } from '../context/CreateContact';
import ToolTipIconButton from '../components/ToolTipIconButton';

// headers for the downloaded report
const REPORT_HEADERS = [
    'ID',
    'Full Name',
    'Address Line 1',
    'Address Line 2',
    'City',
    'Postal/Zip',
    'Province/State',
    'Country',
    'Address Status',
    'Address Errors',
    'Error Message',
] as const;

// type guard to check if object returned from server is of an APIErrorResponse type
const isAPIErrorResponse = (object: any): object is APIErrorResponse => {
    return 'object' in object && object.object === 'error';
};

const downloadReport = (uploadResults: (Contact | APIErrorResponse)[]) => {
    const rows = uploadResults.map((result, i) => {
        if (isAPIErrorResponse(result)) {
            // APIresponseError:
            const emptyDataFields = ','.repeat(REPORT_HEADERS.length - 1);
            return `${i + 1}${emptyDataFields},${result.error.message}`;
        } else {
            // Contact:
            const addressErrors = result.addressErrors
                ? result.addressErrors
                : '';
            return [
                result.id,
                fullName(result),
                result.addressLine1,
                result.addressLine2,
                result.city,
                result.postalOrZip,
                result.provinceOrState,
                result.country,
                result.addressStatus,
                addressErrors,
                '', // <- Empty Error Message for successful contact upload
            ].join(',');
        }
    });

    const payload = [REPORT_HEADERS.join(','), ...rows].join('\n');
    downloadData(payload, 'contacts_upload_report.csv', 'text/csv');
};

const Contacts = () => {
    const history = useHistory();

    const service = useContactsService();

    // state to hold results received from server after uploading contacts
    const [uploadResults, setUploadResults] = useState<
        (Contact | APIErrorResponse)[]
    >([]);

    const paginationFunc = useCallback(
        (params: ListParams) => service.list(params),
        [service]
    );

    const {
        data: contacts,
        loading,
        searchQuery,
        searchText,
        getData: getContacts,
        pagination,
    } = usePagination(paginationFunc);

    const {
        isModalOpen: uploadContactsOpen,
        toggleModal: toggleUploadContactsModal,
    } = useModal();

    const handleUploadComplete = async (
        results: (Contact | APIErrorResponse)[]
    ) => {
        await getContacts();
        setUploadResults(results);
    };

    const handleDownloadReport = () => downloadReport(uploadResults);

    const errorCount = uploadResults.reduce(
        (count, result) => (isAPIErrorResponse(result) ? count + 1 : count),
        0
    );

    return (
        <>
            <UploadContactsDialog
                open={uploadContactsOpen}
                onClose={toggleUploadContactsModal}
                onCompleted={handleUploadComplete}
            />

            <CreateContactProvider>
                <Switch>
                    <Route exact path={ContactRoutes.HOME}>
                        <TopNav
                            showSearch
                            searchText={searchText}
                            searchQuery={searchQuery}
                        />
                        <GridPaper
                            direction="column"
                            spacing={2}
                            data-testid="contacts-page"
                        >
                            <PageHeader title="Contacts">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            history.push(ContactRoutes.CREATE);
                                        }}
                                    >
                                        Create Contact
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={toggleUploadContactsModal}
                                    >
                                        Upload Contacts
                                    </Button>
                                </Grid>
                            </PageHeader>

                            <Grid item>
                                <TableDisplay
                                    columns={[
                                        'Name',
                                        'Company',
                                        'Address',
                                        <>
                                            Address Status
                                            <ToolTipIconButton
                                                icon={InfoIcon}
                                                title="US and Canadian contacts with a 'failed' status are likely undeliverable"
                                                size="small"
                                            />
                                        </>,
                                        'Created At',
                                    ]}
                                    show={loading}
                                    pagination={pagination}
                                    showEmptyTable
                                >
                                    {contacts.map((contact) => {
                                        return (
                                            <TableRow
                                                key={contact.id}
                                                hover
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    history.push(
                                                        `${ContactRoutes.HOME}/${contact.id}`
                                                    );
                                                }}
                                            >
                                                <TableCell>
                                                    {fullName(contact)}
                                                </TableCell>
                                                <TableCell>
                                                    {contact.companyName || ''}
                                                </TableCell>
                                                <TableCell>
                                                    {contact.addressChange && (
                                                        <ToolTipIconButton
                                                            icon={InfoIcon}
                                                            title="The address of this contact has changed."
                                                            color="#edb818"
                                                            size="small"
                                                        />
                                                    )}
                                                    {contact.addressLine1}
                                                </TableCell>
                                                <TableCell>
                                                    {contact.addressStatus}
                                                </TableCell>
                                                <TableCell>
                                                    {formatTableDate(
                                                        contact.createdAt
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableDisplay>
                            </Grid>
                            {uploadResults.length > 0 && (
                                <Paper variant="outlined">
                                    <Box margin={2}>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <Grid item xs={9}>
                                                <LinearProgress
                                                    color={
                                                        errorCount > 0
                                                            ? 'secondary'
                                                            : 'primary'
                                                    }
                                                    variant="determinate"
                                                    value={
                                                        ((uploadResults.length -
                                                            errorCount) /
                                                            uploadResults.length) *
                                                        100
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        disabled={
                                                            !uploadResults?.length
                                                        }
                                                        onClick={
                                                            handleDownloadReport
                                                        }
                                                    >
                                                        Download Report
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            )}
                        </GridPaper>
                    </Route>
                    <Route
                        path={ContactRoutes.CREATE}
                        render={() => (
                            <CreateOrderOriginProvider>
                                <CreateContact />
                            </CreateOrderOriginProvider>
                        )}
                    />
                    <Route path={ContactRoutes.VIEW} component={ViewContact} />
                </Switch>
            </CreateContactProvider>
        </>
    );
};

export default Contacts;
