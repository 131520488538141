import React, { useState, PropsWithoutRef } from 'react';

import type { Contact } from '../services/Contacts';
import { useNotificationContext } from '../context/Notification';
import { useModal } from '../hooks/useModal';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import ContactInput from './ContactInput';
import UploadContactsDialog from './UploadContactsDialog';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';

const ContactOrCSV = (
    props: PropsWithoutRef<{
        label: string;
        contacts: Contact[];
        setContacts: (c: Contact[]) => void;
        setUploadedCSV?: (c: boolean) => void;
        required?: boolean;
        sampleURL?: string;
        textFieldTestId?: string;
    }>
) => {
    const {
        label,
        contacts,
        setContacts,
        setUploadedCSV,
        required,
        sampleURL,
        textFieldTestId,
    } = props;
    const { dispatchSuccess } = useNotificationContext();
    const { isModalOpen, closeModal, openModal } = useModal();

    const [uploadOpen, setUploadOpen] = useState(false);
    const [selectedSingleContact, setSelectedSingleContact] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(false);

    return (
        <>
            <UploadContactsDialog
                open={uploadOpen}
                onClose={() => setUploadOpen(false)}
                onCompleted={(c: Contact[]) => {
                    if (c.length > 0) {
                        setUploadedFile(true);
                        setContacts(c);
                        setUploadedCSV && setUploadedCSV(true);
                        dispatchSuccess('Successfully uploaded contacts');
                    }
                }}
                sampleURL={sampleURL}
            />
            <ConfirmDeleteDialog
                open={isModalOpen}
                onClose={closeModal}
                text="Are you sure you want to remove the uploaded recipients?"
                title="Remove Uploaded Recipients"
                confirm={() => {
                    setUploadedFile(false);
                    setContacts([]);
                }}
            />
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={7}>
                    <ContactInput
                        label={label}
                        contact={contacts.length === 1 ? contacts[0] : null}
                        setContact={(c) => {
                            if (c) {
                                setContacts([c]);
                                setSelectedSingleContact(true);
                                setUploadedCSV && setUploadedCSV(false);
                            } else {
                                setContacts([]);
                                setSelectedSingleContact(false);
                            }
                        }}
                        required={required && contacts.length === 0}
                        disabled={uploadedFile}
                        textFieldTestId={textFieldTestId}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Typography align="center">or</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        fullWidth
                        disabled={selectedSingleContact}
                        style={{ textTransform: 'none' }}
                        onClick={() => {
                            if (uploadedFile) {
                                openModal();
                            } else {
                                setUploadOpen(true);
                            }
                        }}
                    >
                        {uploadedFile
                            ? 'Remove Uploaded Recipients'
                            : 'Upload Recipients'}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default ContactOrCSV;
