import React, { FormEvent, useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useModal } from '../hooks/useModal';

import makeStyles from '@mui/styles/makeStyles';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import ErrorIcon from '@mui/icons-material/Error';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import changePassGraphic from '../img/change-pass-graphic.png';
import {
    ActionType,
    Context as AuthContext,
    State as AuthState,
    EmailPreferences,
    OrderPreviewSendPreference,
} from '../context/Auth';
import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import {
    Service as UsersService,
    useService as useUsersService,
} from '../services/Users';

import {
    AddressStrictness,
    Organization,
    useService as useOrganizationService,
} from '../services/Organization';

import { ChangeEmailDialog } from '../components/ChangeEmailDialog';

import GridContainerLoader from '../components/GridContainerLoader';
import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import APIKeys from '../components/APIKeys';

export const API_DOCS_URL = 'https://docs.postgrid.com';

export const useDialogStyles = makeStyles({
    root: {
        textAlign: 'center',
        borderRadius: '6px',
        '&>.MuiDialog-scrollPaper': {
            '&>.MuiDialog-paper': {
                borderRadius: '8px',
                padding: '20px',
                paddingBottom: '40px',
                minWidth: '500px',
            },
        },
    },
});

const ChangePasswordDialog = (props: {
    service: UsersService;
    userID: string;
    open: boolean;
    onClose: () => void;
}) => {
    const { dispatch } = useContext(NotificationContext);

    const [loading, setLoading] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPssword] = useState('');
    const [formError, setFormError] = useState('');

    const classes = useDialogStyles();

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (newPassword !== confirmPassword)
            setFormError("New Password and Confirm Password don't match");
        else {
            setFormError('');
            try {
                setLoading(true);

                await props.service.update(props.userID, {
                    oldPassword,
                    password: newPassword,
                });

                setOldPassword('');
                setNewPassword('');
                setConfirmPssword('');

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Updated password.',
                });

                props.onClose();
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        }
    };

    return (
        <Dialog {...props} classes={classes}>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    <img
                        src={changePassGraphic}
                        alt="Change password graphic"
                        style={{
                            margin: '10px auto',
                            display: 'block',
                            transform: 'translate(10%, 0)',
                        }}
                    />
                    <DialogTitle>Change Password</DialogTitle>
                    <Box height={10}></Box>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <TextField
                                type="password"
                                variant="outlined"
                                label="Old Password"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                required
                                fullWidth
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type="password"
                                variant="outlined"
                                label="New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                                fullWidth
                                inputProps={{
                                    minLength: 8,
                                }}
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type="password"
                                variant="outlined"
                                label="Confirm New Password"
                                value={confirmPassword}
                                onChange={(e) =>
                                    setConfirmPssword(e.target.value)
                                }
                                required
                                fullWidth
                                inputProps={{
                                    minLength: 8,
                                }}
                                disabled={loading}
                            />
                        </Grid>
                        {formError.length ? (
                            <Grid item>
                                <Alert variant="outlined" color="warning">
                                    {formError}
                                </Alert>
                            </Grid>
                        ) : null}
                    </Grid>
                </DialogContent>
                <Box height={15}></Box>
                <DialogActions>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={5}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    props.onClose();
                                }}
                                disabled={loading}
                                size="large"
                                fullWidth
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                                size="large"
                                fullWidth
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const Profile = (props: {
    loading: boolean;
    state: AuthState;

    name?: string;
    setName: (v: string) => void;

    phoneNumber?: string;
    setPhoneNumber: (v: string) => void;
}) => {
    return (
        <Grid container>
            <Grid item>
                <Typography variant="h6">Profile</Typography>
                <Box my={2}>
                    <GridContainerLoader
                        show={props.loading}
                        items={3}
                        spacing={2}
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Name"
                                fullWidth
                                value={props.name}
                                onChange={(e) => {
                                    props.setName(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Email"
                                inputProps={{
                                    readOnly: true,
                                    spellCheck: false,
                                }}
                                fullWidth
                                value={props.state.user?.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Phone Number"
                                fullWidth
                                value={props.phoneNumber}
                                onChange={(e) => {
                                    props.setPhoneNumber(e.target.value);
                                }}
                            />
                        </Grid>
                    </GridContainerLoader>
                </Box>
            </Grid>
        </Grid>
    );
};

const useBoxStyle = makeStyles({
    root: {
        backgroundColor: '#FAFAFA',
        border: '1px solid #E3E3E3',
        padding: '15px 30px',
        borderRadius: '4px',
        margin: '5px 0',
    },
});

const useTooltipStyle = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        border: '1px solid #E3E3E3',
        padding: '8px',
        borderRadius: '8px',
        color: '#707070',
        fontSize: '14px',
    },
    arrow: {
        color: theme.palette.common.white,
    },
}));

const AddressStrictnessSetting = (props: {
    loading: boolean;
    addressStrictness?: AddressStrictness;
    setAddressStrictness: (v: AddressStrictness) => void;
}) => {
    const classes = useBoxStyle();
    const tooltipClass = useTooltipStyle();

    return (
        <Box>
            <GridContainerLoader
                direction="column"
                items={2}
                show={props.loading}
                spacing={1}
            >
                <Grid item>
                    <Typography variant="h6">Address Strictness</Typography>
                </Grid>
                <Box className={classes.root}>
                    <Grid container item>
                        <Grid item>
                            <Tooltip
                                title={
                                    <>
                                        <ErrorIcon
                                            fontSize="small"
                                            style={{ marginBottom: '-5px' }}
                                        />{' '}
                                        Only allow sending to verified
                                        addresses.
                                    </>
                                }
                                arrow
                                classes={tooltipClass}
                                placement="bottom-end"
                            >
                                <FormControlLabel
                                    label="Verified"
                                    control={
                                        <Radio
                                            color="primary"
                                            checked={
                                                props.addressStrictness ===
                                                AddressStrictness.ALLOW_VERIFIED
                                            }
                                            onClick={() =>
                                                props.setAddressStrictness(
                                                    AddressStrictness.ALLOW_VERIFIED
                                                )
                                            }
                                        />
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip
                                title={
                                    <>
                                        <ErrorIcon
                                            fontSize="small"
                                            style={{ marginBottom: '-5px' }}
                                        />{' '}
                                        Allow sending to addresses that are
                                        automatically corrected.
                                    </>
                                }
                                arrow
                                classes={tooltipClass}
                                placement="bottom-end"
                            >
                                <FormControlLabel
                                    label="Corrected"
                                    control={
                                        <Radio
                                            color="primary"
                                            checked={
                                                props.addressStrictness ===
                                                AddressStrictness.ALLOW_CORRECTED
                                            }
                                            onClick={() =>
                                                props.setAddressStrictness(
                                                    AddressStrictness.ALLOW_CORRECTED
                                                )
                                            }
                                        />
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip
                                title={
                                    <>
                                        <ErrorIcon
                                            fontSize="small"
                                            style={{ marginBottom: '-5px' }}
                                        />{' '}
                                        Allow sending to addresses that fail to
                                        verify.
                                    </>
                                }
                                arrow
                                classes={tooltipClass}
                                placement="bottom-end"
                            >
                                <FormControlLabel
                                    label="Failed"
                                    control={
                                        <Radio
                                            color="primary"
                                            checked={
                                                props.addressStrictness ===
                                                AddressStrictness.ALLOW_FAILED
                                            }
                                            onClick={() =>
                                                props.setAddressStrictness(
                                                    AddressStrictness.ALLOW_FAILED
                                                )
                                            }
                                        />
                                    }
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
            </GridContainerLoader>
        </Box>
    );
};

const OrderPreviewPreferenceSelection = (props: {
    message: string;
    label: string;
    emailPreferences?: EmailPreferences;
    setEmailPreferences: (v: Record<string, string>) => void;
    checkedValue: OrderPreviewSendPreference;
}) => {
    const tooltipClass = useTooltipStyle();

    return (
        <Tooltip
            title={
                <>
                    <ErrorIcon
                        fontSize="small"
                        style={{ marginBottom: '-5px', marginRight: '5px' }}
                    />
                    {props.message}
                </>
            }
            arrow
            classes={tooltipClass}
            placement="bottom-end"
        >
            <FormControlLabel
                label={props.label}
                control={
                    <Radio
                        color="primary"
                        checked={
                            props.emailPreferences
                                ?.orderPreviewSendPreference ===
                            props.checkedValue
                        }
                        onClick={() => {
                            props.setEmailPreferences({
                                ...props.emailPreferences,
                                orderPreviewSendPreference: props.checkedValue,
                            });
                        }}
                    />
                }
            />
        </Tooltip>
    );
};

const OrderPreviewEmailPreferenceSetting = (props: {
    loading: boolean;
    emailPreferences?: EmailPreferences;
    setEmailPreferences: (v: Record<string, string>) => void;
}) => {
    return (
        <GridContainerLoader direction="column" items={2} show={props.loading}>
            <Grid item>
                <Typography variant="h6">Email Preferences</Typography>
            </Grid>
            <Box>
                <Grid
                    container
                    item
                    xs={12}
                    mt={1}
                    justifyContent="space-between"
                >
                    <Grid item xs={3.5}>
                        <Typography variant="subtitle1">
                            Order Previews
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{ fontStyle: 'italic' }}
                        >
                            Get email notifications about order previews that
                            have recently processed
                        </Typography>
                    </Grid>
                    <Grid container item xs={7} justifyContent="space-between">
                        <Grid item xs={4}>
                            <OrderPreviewPreferenceSelection
                                message="Do not send email notifications for
                                            order preview PDFs."
                                label="Don't Send"
                                emailPreferences={props.emailPreferences}
                                setEmailPreferences={props.setEmailPreferences}
                                checkedValue={
                                    OrderPreviewSendPreference.DO_NOT_SEND
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <OrderPreviewPreferenceSelection
                                message="Send email notifications for order
                                            preview PDFs in live mode."
                                label="Live Mode"
                                emailPreferences={props.emailPreferences}
                                setEmailPreferences={props.setEmailPreferences}
                                checkedValue={
                                    OrderPreviewSendPreference.SEND_LIVE_ONLY
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <OrderPreviewPreferenceSelection
                                message="Send email notifications for order
                                            preview PDFs for orders in both live
                                            mode and test mode."
                                label="Live and Test"
                                emailPreferences={props.emailPreferences}
                                setEmailPreferences={props.setEmailPreferences}
                                checkedValue={
                                    OrderPreviewSendPreference.SEND_LIVE_AND_TEST
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </GridContainerLoader>
    );
};

const areObjectsEqual = (obj1: any, obj2: any) => {
    if (obj1 === obj2) return true;
    if (obj1 == null || obj2 == null) return false;
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
        if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
};

const Settings = () => {
    const history = useHistory();

    const orgService = useOrganizationService();

    const { state, dispatch } = useContext(AuthContext);
    const { dispatch: notifDispatch } = useContext(NotificationContext);

    const [org, setOrg] = useState<Organization>();

    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');

    const [addressStrictness, setAddressStrictness] =
        useState<AddressStrictness>();

    const [emailPreferences, setEmailPreferences] =
        useState<EmailPreferences>();

    const service = useUsersService();

    const [loading, setLoading] = useState(true);
    const {
        isModalOpen: changePasswordOpen,
        toggleModal: toggleChangePasswordModal,
    } = useModal();
    const {
        isModalOpen: changeEmailOpen,
        toggleModal: toggleChangeEmailModal,
    } = useModal();

    const getUserChanged = () => {
        if (
            name !== state.user?.name ||
            phoneNumber !== state.user?.phoneNumber ||
            !areObjectsEqual(emailPreferences, state.user?.emailPreferences)
        ) {
            return true;
        }

        return false;
    };

    const userChanged = getUserChanged();
    const orgChanged = addressStrictness !== org?.addressStrictness;

    const changed = userChanged || orgChanged;

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const [user, org] = await Promise.all([
                    service.get(state.user!.id),
                    orgService.get(),
                ]);

                setName(user.name);
                setPhoneNumber(user.phoneNumber);
                setEmailPreferences(
                    user.emailPreferences ?? {
                        orderPreviewSendPreference:
                            OrderPreviewSendPreference.SEND_LIVE_ONLY,
                    }
                );

                setOrg(org);
                setAddressStrictness(org.addressStrictness);
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        })();
    }, [history.location, dispatch, state.user, service, orgService]);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setLoading(true);

            const [user, org] = await Promise.all([
                userChanged
                    ? service.update(state.user!.id, {
                          name,
                          phoneNumber,
                          emailPreferences,
                      })
                    : Promise.resolve(null),
                orgChanged
                    ? orgService.update({ addressStrictness })
                    : Promise.resolve(null),
            ]);

            if (user) {
                dispatch({
                    type: ActionType.UPDATE_USER,
                    user: user,
                });
            }

            if (org) {
                setOrg(org);
            }

            notifDispatch({
                type: MessageType.SUCCESS,
                message: 'Saved settings.',
            });
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    // NOTE It is very important we put dialogs outside the forms, otherwise the
    // events seem to trickle up to the form itself.
    return (
        <>
            <TopNav />

            <ChangePasswordDialog
                userID={state.user!.id}
                open={changePasswordOpen}
                onClose={toggleChangePasswordModal}
                service={service}
            />
            <ChangeEmailDialog
                open={changeEmailOpen}
                onClose={toggleChangeEmailModal}
            />
            <GridPaper direction="column" spacing={2}>
                <form onSubmit={onSubmit}>
                    <Grid item>
                        <Box mb={3}>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h5">
                                        Settings
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                                onClick={toggleChangeEmailModal}
                                            >
                                                Change Email
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                                onClick={
                                                    toggleChangePasswordModal
                                                }
                                            >
                                                Change Password
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading || !changed}
                                            >
                                                Save Changes
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid item>
                            <Box mb={3}>
                                <Alert
                                    variant="outlined"
                                    color="info"
                                    action={
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                window.open(
                                                    API_DOCS_URL,
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            Docs
                                        </Button>
                                    }
                                >
                                    You can use the API keys to create orders
                                    with code or integrate with other software.
                                </Alert>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} alignItems="stretch">
                                <Grid item xs={8}>
                                    <Profile
                                        loading={loading}
                                        state={state}
                                        name={name}
                                        setName={setName}
                                        phoneNumber={phoneNumber}
                                        setPhoneNumber={setPhoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <APIKeys loading={loading} />
                                </Grid>
                                <Grid item xs={12}>
                                    <AddressStrictnessSetting
                                        loading={loading}
                                        addressStrictness={addressStrictness}
                                        setAddressStrictness={
                                            setAddressStrictness
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <OrderPreviewEmailPreferenceSetting
                                        loading={loading}
                                        emailPreferences={emailPreferences}
                                        setEmailPreferences={
                                            setEmailPreferences
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </GridPaper>
        </>
    );
};

export default Settings;
