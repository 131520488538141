import { useMemo } from 'react';

import {
    useService as useBaseService,
    Resource,
    ResourceCreateParams,
    OrderExtraService,
} from './Base';

import { Order, Service as OrdersService, undefinedIfToday } from './Orders';
import { APIErrorResponse, asyncMapChunks, retryBatchWrapper } from './util';

export enum LetterSize {
    US_LETTER = 'us_letter',
    US_LEGAL = 'us_legal',
    A4 = 'a4',
}

export enum AddressPlacement {
    TOP_FIRST_PAGE = 'top_first_page',
    INSERT_BLANK_PAGE = 'insert_blank_page',
}

export enum EnvelopeType {
    STANDARD_DOUBLE_WINDOW = 'standard_double_window',
    FLAT = 'flat',
}

export interface LetterCapabilities {
    sizes: LetterSize[];
}

interface Thumbnail {
    small: string;
    medium: string;
    large: string;
}

export interface Letter extends Order {
    object: 'letter';

    html?: string;
    template?: string;

    color: boolean;
    doubleSided: boolean;

    thumbnail?: Thumbnail;

    addressPlacement?: AddressPlacement;

    returnEnvelope?: string;
    envelope?: string;

    extraService?: OrderExtraService;
    perforatedPage?: number;

    size?: LetterSize | '';
}

export type CreateParams = Omit<
    Letter,
    | 'sendDate'
    | 'to'
    | 'from'
    | 'status'
    | 'thumbnail'
    | 'url'
    | 'imbStatus'
    | 'carrierTracking'
    | keyof Resource
> &
    ResourceCreateParams & {
        to: string;
        from: string;
        sendDate?: Date;
    };

// Omitting metadata until we properly set up FormData with nested fields
type CreateUploadPDFParams = Omit<
    CreateParams,
    'template' | 'html' | 'mergeVariables' | 'metadata' | 'from' | 'to'
> & {
    pdf: File | Blob;
} & ( // Either provide contact IDs or provide the info to create a contact
        | {
              from: string;
              to: string;
          }
        | {
              'from[companyName]': string;
              'from[addressLine1]': string;
              'from[countryCode]': string;
              'to[companyName]': string;
              'to[addressLine1]': string;
              'to[countryCode]': string;
          }
    );

type CreateBatchDatum = Omit<
    CreateParams,
    | 'template'
    | 'html'
    | 'pdf'
    | 'from'
    | 'to'
    | 'extraService'
    | 'express'
    | 'size'
> & { to: string; from: string };

export type CreateBatchParams = Pick<
    CreateParams,
    | 'template'
    | 'express'
    | 'extraService'
    | 'returnEnvelope'
    | 'envelope'
    | 'sendDate'
    | 'size'
> & {
    pdf?: File | string;
    data: CreateBatchDatum[];
    handleProgress: (count: number) => void;
};

// TODO: Factor into Batch stuff -> copied from Postcard
interface CreateBatchResponse {
    object: 'batch';
    data: (Letter | APIErrorResponse)[];
}

export class Service extends OrdersService<Letter> {
    async create(data: CreateParams) {
        return await this.base.fetchAPI<Letter>(`/${this.route}`, {
            method: 'POST',
            body: {
                ...data,
                sendDate: undefinedIfToday(data.sendDate),
                metadata: { postgrid_dashboard: '' },
            },
        });
    }

    async createUploadPDF(params: CreateUploadPDFParams) {
        const data = new FormData();
        data.set('metadata[postgrid_dashboard]', '');

        for (let [key, value] of Object.entries(params)) {
            if (value === undefined) {
                continue;
            }

            data.set(key, value as any);
        }

        return await this.base.fetchAPI<Letter>(`/${this.route}`, {
            method: 'POST',
            body: data,
        });
    }

    async createBatch(params: CreateBatchParams) {
        const batchRequest = async (
            batch: CreateBatchDatum[]
        ): Promise<CreateBatchResponse> => {
            const res = await this.base.fetchAPI<CreateBatchResponse>(
                `/${this.route}/batch`,
                {
                    method: 'POST',
                    body: {
                        ...params,
                        sendDate: undefinedIfToday(params.sendDate),
                        pdf: await (async () => {
                            if (!params.pdf || typeof params.pdf === 'string') {
                                return params.pdf;
                            }

                            // Convert to base64 data URI: https://stackoverflow.com/a/18650249
                            return await new Promise((resolve) => {
                                const reader = new FileReader();
                                reader.onloadend = () => resolve(reader.result);
                                reader.readAsDataURL(params.pdf as File);
                            });
                        })(),
                        data: batch,
                        handleProgress: undefined,
                    },
                }
            );

            params.handleProgress(batch.length);

            return res;
        };

        return (
            await asyncMapChunks(
                params.data,
                250,
                retryBatchWrapper(batchRequest)
            )
        ).flatMap((d) => d.data);
    }

    async capabilities(destinationCountryCode: string) {
        const params = new URLSearchParams({
            destinationCountryCode,
        });

        return await this.base.fetchAPI<LetterCapabilities>(
            `/${this.route}/capabilities?${params.toString()}`
        );
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'letters'), [base]);
};
