import React from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import HideableTextField from './HideableTextField';
import Grid from '@mui/material/Grid';
import CopyToClipboard from './CopyToClipboard';

type APIKeyProps = Omit<TextFieldProps, 'value'> & {
    title: string;
    value?: string;
};

const APIKey = ({ title, value, ...props }: APIKeyProps) => {
    if (!value) return null;
    return (
        <Grid item xs={6}>
            <HideableTextField
                variant="outlined"
                label={title}
                id={title.toLocaleLowerCase().replace(/ /g, '-')}
                size="small"
                fullWidth
                inputProps={{
                    spellCheck: false,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    endAdornment: <CopyToClipboard value={value} />,
                }}
                value={value}
                {...props}
            />
        </Grid>
    );
};

export default APIKey;
