import { useMemo } from 'react';

import { User, EmailPreferences } from '../context/Auth';
import { useService as useBaseService } from './Base';
import { Service as ResourceService } from './DeletableResource';

import { Organization } from './Organization';

interface CreateWithOrganizationParams {
    name: string;
    email: string;
    countryCode?: string;
    password?: string;
    phoneNumber?: string;
    organizationName: string;
    recaptchaToken?: string;
    accessToken?: string;
}

interface CreatePasswordResetParams {
    email: string;
}

interface ResetPasswordParams {
    password: string;
}

interface ResetPasswordResponse {
    email: string;
}

type VerifyEmailResponse = ResetPasswordResponse;

interface UserOrganization {
    user: User;
    organization: Organization;
}

interface UpdateParams {
    name?: string;
    oldPassword?: string;
    password?: string;
    phoneNumber?: string;
    roles?: string[];
    email?: string;
    emailPreferences?: EmailPreferences;
}

export class Service extends ResourceService<User> {
    async createWithOrganization(params: CreateWithOrganizationParams) {
        return await this.base.fetchAPI<UserOrganization>(
            `/${this.route}/signup`,
            {
                method: 'POST',
                body: params,
            }
        );
    }

    async createPasswordReset(params: CreatePasswordResetParams) {
        // Ignore the response, it just echoes the params
        await this.base.fetchAPI(`/${this.route}/password_resets`, {
            method: 'POST',
            body: params,
        });
    }

    async verifyEmail(token: string) {
        return await this.base.fetchAPI<VerifyEmailResponse>(
            `/${this.route}/email_verifications/${token}`,
            {
                method: 'POST',
            }
        );
    }

    async resetPassword(token: string, params: ResetPasswordParams) {
        return await this.base.fetchAPI<ResetPasswordResponse>(
            `/${this.route}/password_resets/${token}`,
            {
                method: 'POST',
                body: params,
            }
        );
    }

    async update(id: string, params: UpdateParams) {
        return await this.base.fetchAPI<User>(`/${this.route}/${id}`, {
            method: 'POST',
            body: params,
        });
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'users'), [base]);
};
