import React, {
    useState,
    useEffect,
    PropsWithoutRef,
    useCallback,
} from 'react';

import { LocalFile, parse, ParseStepResult } from 'papaparse';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import LinearProgress from '@mui/material/LinearProgress';

import { useNotificationContext } from '../context/Notification';
import { APIErrorResponse } from '../services/util';

import {
    Contact,
    CreateParams,
    useService as useContactsService,
} from '../services/Contacts';

import FileUpload from './FileUpload';
import RowErrors from './RowErrors';

export interface Row {
    Description?: string;
    'First Name'?: string;
    'Last Name'?: string;
    Email?: string;
    'Phone Number'?: string;
    'Company Name'?: string;
    'Job Title'?: string;
    Address?: string;
    'Address 2'?: string;
    City?: string;
    'Province or State'?: string;
    'Postal or Zip'?: string;
    'Country Code'?: string;
}

const headers = [
    'Description',
    'First Name',
    'Last Name',
    'Email',
    'Phone Number',
    'Company Name',
    'Job Title',
    'Address',
    'Address 2',
    'City',
    'Province or State',
    'Postal or Zip',
    'Country Code',
];

const normalizeKey = (key: string) => key.toLowerCase().replace(/\s+/g, '');

const isValidRow = (r: Row) => {
    // Find keys for which there are valid non-empty values
    const normKeys = Object.entries(r)
        .filter(([_, v]) => typeof v === 'string' && v.trim().length !== 0)
        .map(([k, _]) => normalizeKey(k));

    return (
        (normKeys.includes('firstname') || normKeys.includes('companyname')) &&
        normKeys.includes('address') &&
        normKeys.includes('countrycode')
    );
};

const validateHeader = (
    header: string,
    uniqueValue: string,
    uniqueHeaderIndex: number,
    headerIndex: number
) => {
    if (header.toLocaleLowerCase().includes(uniqueValue)) {
        // A value of 0 means we have not seen the value before
        // as the values are initialized to 0
        if (uniqueHeaderIndex === 0) {
            // Return the index + 1 because the header could be at
            // position 0 and then our checks would not work for checking
            // if the index is 0 or negative.
            return headerIndex + 1;
        } else {
            return -1;
        }
    }
    return uniqueHeaderIndex;
};

const updateHeaders = (
    headers: string[],
    value: string,
    first: number,
    second?: number
) => {
    // Subtract 1 from the index when we check the position because we
    // add 1 to the index when we validate the header
    if (second !== undefined) {
        if (first > 0 && second === 0) {
            headers[first - 1] = value;
        }
        if (second > 0 && first === 0) {
            headers[second - 1] = value;
        }

        return;
    }

    if (first > 0) {
        headers[first - 1] = value;
    }
};

export const parseHeaders = (headers: string[]) => {
    // Store a number to check the index of the header.
    //
    // Store the index of the header + 1 in the number if it is the first time
    // we have seen the unique header (value is 0).
    // Add +1 to the index to be able to check the case of the first time we
    // are seeing the unique value ('name' set to 0 initially) and that
    // value is the first in the header list (index 0).
    // When we insert the value into the list, subtract 1 from the index value
    // to account for that.
    // If we have seen the header before (value is greater than 0), then we
    // set the value to a negative value to indicate that this value is not
    // unique.
    let nameIndex = 0;
    let provinceIndex = 0;
    let stateIndex = 0;
    let countryIndex = 0;
    let zipIndex = 0;
    let postalIndex = 0;

    headers.forEach((header, i) => {
        nameIndex = validateHeader(header, 'name', nameIndex, i);
        provinceIndex = validateHeader(header, 'province', provinceIndex, i);
        stateIndex = validateHeader(header, 'state', stateIndex, i);
        countryIndex = validateHeader(header, 'country', countryIndex, i);
        zipIndex = validateHeader(header, 'zip', zipIndex, i);
        postalIndex = validateHeader(header, 'postal', postalIndex, i);
    });

    updateHeaders(headers, 'Company Name', nameIndex);
    updateHeaders(headers, 'Province or State', provinceIndex, stateIndex);
    updateHeaders(headers, 'Country Code', countryIndex);
    updateHeaders(headers, 'Postal or Zip', postalIndex, zipIndex);

    return headers;
};

export const parseCSV = (
    file: File
): Promise<{ rows: Row[]; invalidRowCount: number }> => {
    return new Promise((resolve) => {
        let headers: string[] = [];
        let invalidRowCount = 0;
        let rows: Row[] = [];
        let first = true;

        parse(file as LocalFile, {
            step: (row: ParseStepResult<string[]>) => {
                // First row will be the headers
                if (first) {
                    first = false;
                    headers = parseHeaders(row.data);
                    return;
                }

                // Create a `row` object from the headers
                const _row: Record<string, string> = {};
                headers.forEach((key, i) => (_row[key] = row.data[i]));

                if (isValidRow(_row)) {
                    rows.push(_row);
                } else {
                    invalidRowCount++;
                }
            },
            complete: () => {
                resolve({ rows, invalidRowCount });
            },
        });
    });
};

const UploadContactsDialog = (
    props: PropsWithoutRef<{
        open: boolean;
        onClose: (e: {}) => void;
        onCompleted?: (contacts: Contact[]) => void;
        sampleURL?: string;
    }>
) => {
    const service = useContactsService();
    const { dispatchError } = useNotificationContext();

    const [file, setFile] = useState<File | null>(null);

    const [rows, setRows] = useState<Row[]>([]);
    const [invalidRowCount, setInvalidRowCount] = useState(0);

    const [creatingContacts, setCreatingContacts] = useState(false);
    const [processedCount, setProcessedCount] = useState(0);

    useEffect(() => {
        let stopEffect = false;
        if (!file) {
            setRows([]);
            setInvalidRowCount(0);

            return;
        }

        (async () => {
            const { rows, invalidRowCount } = await parseCSV(file);
            if (stopEffect) return;
            setRows(rows);
            setInvalidRowCount(invalidRowCount);
        })();

        return () => {
            stopEffect = true;
        };
    }, [file, setRows, setInvalidRowCount]);

    const processRows = useCallback(async () => {
        setCreatingContacts(true);

        const contactsToCreate: CreateParams[] = [];

        for (const row of rows) {
            try {
                const normRow: Record<string, string> = {};

                for (const [key, value] of Object.entries(row)) {
                    normRow[normalizeKey(key)] = value;
                }

                contactsToCreate.push({
                    description: normRow.description,

                    addressLine1: normRow.address!,
                    addressLine2: normRow.address2,
                    city: normRow.city,
                    postalOrZip: normRow.postalorzip,
                    provinceOrState: normRow.provinceorstate,
                    countryCode: normRow.countrycode,

                    firstName: normRow.firstname,
                    lastName: normRow.lastname,
                    email: normRow.email,
                    phoneNumber: normRow.phonenumber,
                    companyName: normRow.companyname,
                    jobTitle: normRow.jobtitle,

                    // Remove all of our built in headers and put the rest as metadata
                    metadata: (() => {
                        const dup: any = { ...row };

                        for (const header of headers) {
                            delete dup[header];
                        }

                        if (dup.hasOwnProperty('Amount')) {
                            dup.amount = parseFloat(dup['Amount']);
                            delete dup['Amount'];
                        }

                        if (dup.hasOwnProperty('Memo')) {
                            dup.memo = dup['Memo'];
                            delete dup['Memo'];
                        }

                        if (dup.hasOwnProperty('Cheque Number')) {
                            dup.chequeNumber = parseInt(dup['Cheque Number']);
                            delete dup['Cheque Number'];
                        }

                        return dup;
                    })(),
                });
            } catch (err: any) {
                console.error(err);
                dispatchError(err.message);
            }
        }

        const contacts: Contact[] = await (async () => {
            try {
                const contacts: (Contact | APIErrorResponse)[] =
                    await service.createBatch({
                        data: contactsToCreate,
                        handleProgress: (count) =>
                            setProcessedCount((prev) => prev + count),
                    });

                if (contacts.every((contact) => contact.object === 'error')) {
                    throw new Error(
                        (contacts[0] as APIErrorResponse).error.message
                    );
                }

                return contacts as Contact[];
            } catch (err: any) {
                console.error(err);
                dispatchError(err.message);
            }

            return [];
        })();

        setCreatingContacts(false);

        if (props.onCompleted) {
            props.onCompleted(contacts);
        }

        // Clear the dialog state
        setFile(null);
        setProcessedCount(0);
        setRows([]);
        setInvalidRowCount(0);
    }, [rows, service, props, dispatchError]);

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            data-testid="upload-contacts-dialog"
        >
            <DialogContent>
                <DialogTitle>
                    <Typography variant="h5" component={'span'}>
                        Upload a CSV File
                    </Typography>
                </DialogTitle>
                <DialogContentText>
                    <Typography component={'span'}>
                        You can create a large number of contacts at once by
                        uploading a CSV file. Download a sample CSV file{' '}
                        <Link
                            href={
                                props.sampleURL ||
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/sample_recipients.csv'
                            }
                        >
                            here
                        </Link>
                        . Note that you can add your own columns and those will
                        be supplied as variable data to your orders.
                    </Typography>
                </DialogContentText>
                <FileUpload
                    label="Upload CSV File"
                    accept="text/csv"
                    file={file}
                    setFile={setFile}
                    disabled={creatingContacts}
                />
                <RowErrors
                    rows={rows}
                    invalidRowCount={invalidRowCount}
                    file={file}
                />
                {creatingContacts && (
                    <LinearProgress
                        color="primary"
                        variant="determinate"
                        value={(processedCount / (rows.length || 1)) * 100}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={5}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={(e) => {
                                props.onClose(e);
                            }}
                            size="large"
                            fullWidth
                            disabled={creatingContacts}
                            data-testid="cancel-upload-contacts-button"
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={async (e) => {
                                await processRows();
                                props.onClose(e);
                            }}
                            size="large"
                            fullWidth
                            disabled={creatingContacts || rows.length <= 0}
                            data-testid="upload-contacts-button"
                        >
                            Upload
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default UploadContactsDialog;
