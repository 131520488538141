import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { Context as AuthContext, State as AuthState } from '../context/Auth';
import { Context as ModeContext } from '../context/Mode';
import {
    MessageType,
    Action as NotificationAction,
    Context as NotificationContext,
} from '../context/Notification';
import useLogout from '../hooks/useLogout';
import { ErrorRoutes, SSORoutes } from '../routes';

import {
    APIRequestError,
    APIErrorType,
    APIRequestInit,
    fetchAPI,
} from './util';

export enum OrderExtraService {
    CERTIFIED = 'certified',
    CERTIFIED_RETURN_RECEIPT = 'certified_return_receipt',
    REGISTERED = 'registered',
}

export enum OrderMailingClass {
    FIRST_CLASS = 'first_class',
    STANDARD_CLASS = 'standard_class',
}

export interface Resource {
    object:
        | 'letter'
        | 'self_mailer'
        | 'postcard'
        | 'cheque'
        | 'bank_account'
        | 'template'
        | 'template_editor_session'
        | 'return_envelope'
        | 'return_envelope_order'
        | 'contact'
        | 'api_log'
        | 'webhook'
        | 'webhook_invocation'
        | 'tracker'
        | 'logistics_analytics'
        | 'mailing_list_import'
        | 'custom_envelope';

    id: string;
    description?: string;
    live: boolean;
    metadata?: Record<string, any>;

    createdAt: Date;
    updatedAt: Date;
}

export type ResourceCreateParams = Omit<
    Resource,
    'id' | 'live' | 'createdAt' | 'updatedAt' | 'object'
>;

export class Service {
    history: ReturnType<typeof useHistory>;
    notify: (action: NotificationAction) => void;
    live: boolean;
    logout: () => void;
    authState?: AuthState;
    redirectRoute: string;

    constructor(
        history: Service['history'],
        notify: Service['notify'],
        live: boolean,
        logout: () => void,
        redirectRoute: string,
        authState?: AuthState
    ) {
        this.history = history;
        this.notify = notify;
        this.live = live;
        this.logout = logout;
        this.authState = authState;
        this.redirectRoute = redirectRoute;
    }

    // TODO Make generic 'fetch' wrapper here which handles errors as well
    async internalFetchAPI(
        path: string,
        init?: APIRequestInit & {
            forceLive?: boolean;
            allow404?: boolean;
            silent?: boolean;
        }
    ): Promise<any> {
        if (this.authState && this.authState.tokens) {
            init = {
                ...init,
                headers: {
                    ...init?.headers,
                    Authorization: `Bearer ${
                        this.live || init?.forceLive
                            ? this.authState.tokens.live
                            : this.authState.tokens.test
                    }`,
                },
            };
        }

        try {
            return await fetchAPI(path, init);
        } catch (err: any) {
            if (err instanceof APIRequestError) {
                if (
                    err.type === APIErrorType.TOKEN_EXPIRED ||
                    err.type === APIErrorType.AUTHENTICATION_MISSING ||
                    err.type === APIErrorType.INVALID_TOKEN
                ) {
                    this.logout();
                    this.history.push(this.redirectRoute);
                }

                if (
                    err.type === APIErrorType.PERMISSION_MISSING ||
                    err.type === APIErrorType.LIVE_PERMISSION_MISSING
                ) {
                    this.history.replace(ErrorRoutes.UNAUTHORIZED);
                }

                if (init && init.allow404 && err.status === 404) {
                    return null;
                }

                if (err.status === 503 && err.type === 'maintenance_error') {
                    this.history.push(`/maintenance?msg=${err.message}`);
                }
            }

            if (!init?.silent) {
                this.notify({
                    type: MessageType.ERROR,
                    message: err.message,
                });
            }

            throw err;
        }
    }

    async fetchAPI<T>(
        path: string,
        init?: APIRequestInit & { silent?: boolean; forceLive?: boolean }
    ): Promise<T> {
        return this.internalFetchAPI(path, init);
    }

    async fetchAPIAllow404<T>(
        path: string,
        init?: APIRequestInit
    ): Promise<T | null> {
        return this.internalFetchAPI(path, {
            ...init,
            allow404: true,
        });
    }
}

export const useService = () => {
    const history = useHistory();
    const { isAuthenticated } = useAuth0();

    const logout = useLogout();
    const { dispatch } = useContext(NotificationContext);
    const { state } = useContext(AuthContext);
    const { live } = useContext(ModeContext);

    return useMemo(
        () =>
            new Service(
                history,
                dispatch,
                live,
                logout,
                isAuthenticated ? SSORoutes.LOGIN : '/login',
                state
            ),
        [dispatch, history, live, state, logout, isAuthenticated]
    );
};
